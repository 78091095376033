<template>
  <div>
    <b-tabs nav-wrapper-class="faq-navigation" nav-class="nav-left">
      <b-tab>
        <template #title class="bg-primary">
          <feather-icon icon="MenuIcon" />
          <span>Müşteri Bilgileri</span>
        </template>
        <card-customer-search
          entitytype="cargo"
          @customerdevices="emitCustomerDevices"
        ></card-customer-search>
      </b-tab>
      <b-tab>
        <template #title class="bg-primary">
          <feather-icon icon="MenuIcon" />
          <span>Takip Formu</span>
        </template>
        <div class="card">
          <div class="card-header">
            <div class="card-title">Kargo Takibi Oluştur</div>
          </div>
          <div class="card-body p-0">
            <div class="border-top border-bottom p-1">
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <small class="text-sm text-uppercase font-semibold"
                      >Yön</small
                    >
                    <v-select
                      v-model="cargo.cargo.type"
                      :options="[
                        { label: 'Müşteriden Gelen Kargo', value: 'take' },
                        { label: 'Müşteriye Giden Kargo', value: 'send' },
                      ]"
                      :reduce="(option) => option.value"
                      :searchable="false"
                      :clearable="false"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <small class="text-sm text-uppercase font-semibold"
                      >Planlanan Tarih</small
                    >
                    <flat-pickr
                      class="form-control"
                      v-model="cargo.cargo.planned_date"
                      :config="{
                        enableTime: false,
                        altFormat: 'd.m.Y',
                        altInput: true,
                        dateFormat: 'Y-m-d',
                        locale: 'tr',
                      }"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <small class="text-sm text-uppercase font-semibold"
                      >Gerçekleşen Tarih</small
                    >
                    <flat-pickr
                      class="form-control"
                      v-model="cargo.cargo.complated_date"
                      :config="{
                        enableTime: false,
                        altFormat: 'd.m.Y',
                        altInput: true,
                        dateFormat: 'Y-m-d',
                        locale: 'tr',
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8">
                  <div class="form-group">
                    <small class="font-medium text-uppercase"
                      >Kargo Firması</small
                    >
                    <v-select
                      v-model="cargo.cargo.cargo_company_id"
                      :searchable="false"
                      :clearable="true"
                      :options="global.cargo_companies"
                      :reduce="(curr) => curr.value"
                    />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <small class="font-medium text-uppercase text-primary"
                      >Kargo Takip Kodu</small
                    >
                    <input
                      v-model="cargo.cargo.cargo_tracking_code"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Karşı Ödemeli</label
                    >
                    <v-select
                      v-model="cargo.cargo.customer_will_pay"
                      :searchable="true"
                      :clearable="false"
                      :options="global.yesno"
                      :reduce="(curr) => curr.value"
                    />
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase"
                      >Kargo Bedeli</label
                    >
                    <input
                      v-model="cargo.cargo.cargo_price"
                      type="text"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label class="font-medium text-uppercase">Ofis</label>
                <v-select
                  v-model="cargo.cargo.target_office_id"
                  :searchable="true"
                  :clearable="false"
                  :options="global.offices"
                  :reduce="(curr) => curr.value"
                />
              </div>

              <b-row>
                <b-col md="6" lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">İl</label>
                    <v-select
                      :searchable="true"
                      :clearable="false"
                      :options="global.cities"
                      :reduce="(curr) => curr.value"
                      v-on:input="changeCity()"
                      v-model="cargo.cargo.city_id"
                    />
                  </div>
                </b-col>
                <b-col md="6" lg="6">
                  <div class="form-group">
                    <label class="font-medium text-uppercase">İlçe</label>
                    <v-select
                      :searchable="true"
                      :clearable="true"
                      :reduce="(curr) => curr.district_id"
                      :options="datasource.districts"
                      v-model="cargo.cargo.district_id"
                    />
                  </div>
                </b-col>
              </b-row>
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Açık Adres</small
                >
                <textarea
                  v-model="cargo.cargo.address"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Notlar</small
                >
                <textarea
                  v-model="cargo.cargo.notes"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>

              <div class="form-group mt-2">
                <button class="btn btn-primary" @click="saveCargoItem()">
                  Kaydet
                </button>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab v-if="cargo.cargo.c_id > 0 && false ">
        <template #title class="bg-primary">
          <feather-icon icon="MenuIcon" />
          <span>Paket İçeriği</span>
        </template>
        <div class="card">
          <div class="card-header">
            <div class="card-title">Kargo Paketi</div>
            <div>
              <button
                v-on:click="datasource.packet_show_to = 'list'"
                class="btn btn-sm"
                :class="{ 'btn-primary': datasource.packet_show_to == 'list' }"
              >
                Görüntüle
              </button>
              <button
                v-on:click="datasource.packet_show_to = 'add'"
                class="btn btn-sm"
                :class="{ 'btn-primary': datasource.packet_show_to == 'add' }"
              >
                Ekle
              </button>
            </div>
          </div>
          <div class="card-body pt-0 mt-0 border-top">
            <div v-if="datasource.packet_show_to == 'add'" class="mt-1">
              <b-form-radio-group
                v-model="datasource.packet_type"
                :options="[
                  { label: 'Cihaz', value: 'device' },
                  { label: 'Diğer', value: 'other' },
                ]"
                class=""
                value-field="value"
                text-field="label"
              />
              <div>
                <div
                  v-if="datasource.packet_type == 'other'"
                  class="form-group pt-1 pb-1"
                >
                  <small class="font-weight-bold text-uppercase"
                    >Nesne Ekle</small
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="kargo paketine eklediğiniz diğer nesneleri yazın"
                    />
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button">
                        Kaydet
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  v-if="datasource.packet_type == 'device'"
                  class="ml-n2 mr-n2 mt-2"
                >
                  <b-table
                    ref="table_customer_devices"
                    responsive
                    striped
                    small
                    hover
                    :items="customer_devices"
                    :fields="datasource.table_customer_devices.fields"
                    class="mb-0 align-top"
                    show-empty
                  >
                    <template #cell(task_type)="data">
                      <div class="font-weight-bold">
                        <div>{{ data.item.task_type_text }}</div>
                        <small class="font-weight-bold">{{
                          data.item.priority_text
                        }}</small>
                      </div>
                    </template>
                    <template #cell(task_definition)="data">
                      <div class="font-weight-bold">
                        {{ data.value }}
                      </div>
                    </template>
                    <template #cell(device_item)="data">
                      <div class="font-weight-bold">
                        {{ data.value.device_name }}
                      </div>
                    </template>
                    <template #cell(date_item)="data">
                      <div>
                        {{ data.value }}
                      </div>
                    </template>
                    <template #cell(action_item)="data">
                      <button
                        class="btn btn-sm btn-primary"
                        v-on:click="addCargoItem(data.item, 'device')"
                      >
                        Ekle
                      </button>
                    </template>
                    <template #empty>
                      <div class="pt-3 pb-3 text-center">
                        Listelenecek bir kayıt bulunmuyor.
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import CardCustomerSearch from "@/views/common/CardCustomerSearch";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";

export default {
  components: {
    StatusBadge,
    vSelect,
    flatPickr,
    CardCustomerSearch,
  },
  data() {
    return {
      datasource: {
        packet_type: "device",
        packet_show_to: "list",
        customer_devices: [],
        cargo_items: [],
        table_customer_devices: {
          fields: [
            {
              key: "device_id",
              label: "Cihaz No",
              sortable: true,
              selected: true,
            },
            {
              key: "device_name",
              label: "Cihaz",
              sortable: true,
              selected: true,
            },
            { key: "date_item", label: "Tarih", sortable: true },
            { key: "action_item", label: "", sortable: true },
          ],
        },
        table: {
          fields: [
            {
              key: "task_type",
              label: "Tür",
              sortable: true,
              selected: true,
            },
            {
              key: "task_name",
              label: "Görev",
              sortable: true,
              selected: true,
            },
            {
              key: "device_item",
              label: "Cihaz",
              sortable: true,
              selected: true,
            },
            {
              key: "staff_item",
              label: "Personel",
              sortable: true,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            { key: "status_item", label: "Durum", sortable: true },
          ],
          selected: null,
        },
      },
    };
  },
  methods: {
    ...mapActions(["saveCargo"]),
    ...mapMutations(["clearCargo"]),
    emitCustomerDevices($emit) {
      this.datasource.customer_devices = $emit;
    },
    saveCargoItem() 
    {
      this.saveCargo().then((q) => 
      {
        if (!q && !q.success) { return; }

        this.$router.replace({
          name: "cargotracking-detail",
          query: { uniq: q.data.cargo.c_uniq },
        });
      });
    },
    addCargoItem(item, type) {
      let item_name = "";
      if (type == "device") {
        item_name = item.device_name;
      }
      let new_item = {
        type: "type",
        device_id: item.device_id,
        item_name: item_name,
      };
      this.cargo.cargo_items.push(new_item);
    },
    getDefinitionName(id) {
      return utils.getDefinitionName(id);
    },
    changeCity() {
      this.datasource.districts = [];
      this.getDistricts();
    },
    getDistricts() {
      let city_id = this.cargo.cargo.city_id;
      let items = utils.getDistricts(city_id, { viewtype: "vselect" });
      this.datasource.districts = items;
    },
    moment(v) {
      return moment(v);
    },
  },
  created() {

    this.clearCargo();
    let au = utils.getActiveUser();
    this.cargo.cargo.target_office_id = au.office_id;
    this.cargo.cargo.city_id = "34";
    this.cargo.cargo.district_id = "0";
    this.cargo.cargo.c_id = 0;
    this.cargo.cargo.status = 'pending';
    this.getDistricts();

  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
      cargo: (state) => state.cargo,
    }),
    customer_devices() {
      let result = [];
      this.datasource.customer_devices.forEach((q) => {
        let item = {
          item: q,
          device_id: q.device_id,
          device_name: q.device_name,
          date_item: this.moment(q.created_props.created_at).format(
            "DD.MM.YYYY HH:mm"
          ),
        };
        result.push(item);
      });
      return result;
    },
    list_service_task() {
      let result = [];
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>